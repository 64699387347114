// export const env = { ...process.env, ...window['env'] };

const DEFAULT_DEVELOPMENt_ENVS = {
  ...process.env,
  REACT_APP_HOST_IP_ADDRESS: "localhost:7777",
  // WC_CLIENT: "5009267",
  // PUBLIC_URL: "https://popsv3.relysoft.in",
  // RELIABLE_SOFTWARE_URL: "https://popsv3.relysoft.in",
};

const generateEnv = () => {
  if (process.env.NODE_ENV !== "production") {
    return DEFAULT_DEVELOPMENt_ENVS;
  }
  return { ...process.env, ...window.env };
};

export const env = generateEnv();
