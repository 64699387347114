
import './Customer.css';
import { useEffect, useState } from "react";
import { InputGroup, Table } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import HeaderCustomer from './HeaderCustomer';
import GlobalVariable from '../../GlobalVariable';
import TransferData from '../../TransferData';
import ApiHandler from '../../ApiHandler';
import { useDispatch, useSelector } from 'react-redux';
import { arraylist, remarkMessagebycomp, totalprice, userOrderAction } from './Userproducts/UserOrders';
import { toast } from 'react-toastify';



const indialOrder = {

    wClientid: GlobalVariable.W2,
    CustCode: 0,
    repcode: 0,
    uniqueOrderID: GlobalVariable.W2,
    orderType: 'web',
    rclientid: null,
    urgent: 0,
    remarks: '',
    mobNo: "",
    orderItems: []

}
export const selectedProductNameLocalStorage = "selcted-Products";
export default function OrderByComp_Customer() {

    const [data, setData] = useState([]);
    const [productData, setProductData] = useState(indialOrder.orderItems);
    const [searchProduct, SetsearchProduct] = useState([]);
    const [showcomp, setShowComp] = useState(false);
    const [comCode, setComCode] = useState('');

    const [showList, setShowList] = useState(false)
    const [saveitmes, setsaveitmes] = useState('')
    const [order, setOrder] = useState(indialOrder);
    const [remarksMessageBYcomp, setRemarksMessageBYcomp] = useState();

    const [hideDateTime, sethideDateTime] = useState(false)
    const [increasePrice, setIncreasePrice] = useState(0);
    const [valuesqtys, setvaluesqtys] = useState();
    //const [Corder, setCorder] = useState([])
    const [compaNames, setcompaNames] = useState("select")
    const [OrderbyCompRate, setOrderbyCompRate] = useState()
    const dispatch = useDispatch();







    useEffect(() => {

        ApiHandler.get({

            //url: localUrl + "api/OwnApp/GetComOrderList",
            url: "/api/POPSReports/AllCompanies/" + GlobalVariable.wcClient,

        })
            .then(((result) => {
                console.log(result);
                setData(result);
            }))
            .catch(err => {
                console.error(err);
                if (err.response && err.response.status && err.response.status === 401) {
                    console.log(err.response);
                    //   RefreshTokenHandler();
                    //  ToastError("Token has expired Please refresh");
                }

            })
    }, [])





    useEffect(() => {
        if (comCode) {

            ApiHandler.post({
                url: "api/Search/ProductByCompanyCode/",
                data: {

                    wClientid: GlobalVariable.wcClient + "",
                    search: comCode + ""
                }
            })
                .then(((res) => {
                    console.log(res.data);
                    setProductData([...res.data])
                    SetsearchProduct([...res.data])
                }))
                .catch(err => {
                    console.error(err);
                    if (err.response && err.response.status && err.response.status === 401) {
                        console.log(err.response);
                        //   RefreshTokenHandler();
                        //  ToastError("Token has expired Please refresh");
                    }
                })



        }
    }, [comCode]

    )


    const filterBySearch = (e) => {
        SetsearchProduct(productData.filter(p => p.prodName.toLowerCase().includes(e.target.value)))

    };


    const customerOrder = useSelector((state) => state.userOrders.searchProduct)

    const handleSubmit = (e) => {
        // setIncreasePrice('')
        SetsearchProduct(indialOrder.orderItems)
        //customerOrder('')
        e.preventDefault();
        toast.success("Products are sent to the cart ");

        // dispatch(userOrderAction.setProductData(productData))
        const sendData = searchProduct
            .filter(c => c.qty)
            .map(data => {
                return {
                    ...data, qty: !data.qty ? 0 : data.qty,
                    productCode: data.prodCode, bycompPRICE: increasePrice
                }
            });
        console.log({ sendData });
        //dispatch(userOrderAction.setselecteddata())
        //dispatch(userOrderAction.setProductData(sendData))

        let x = JSON.parse(localStorage.getItem(selectedProductNameLocalStorage));
        x = x ? x : [];
        var p = x.concat(sendData)

        const l = p.reduce((n, i) => {
            console.log(x);
            if (!n.find((val) => val.productCode === i.productCode)) {
                n.push(i)

            }

            return n

        }, [])

        dispatch(userOrderAction.setProductData(l))
        dispatch(remarkMessagebycomp(remarksMessageBYcomp))
        dispatch(totalprice(increasePrice))
        dispatch(arraylist(sendData))

        localStorage.setItem("selcted-Products", JSON.stringify(l));
        //console.log(customerOrder.concat(sendData));

    };

    useEffect(() => {
        let isComponentMounted = true;
        if (isComponentMounted) {
            const sendData = searchProduct
                .filter(c => c.qty)
                .map(data => {
                    // window.localStorage.setItem('Quty', JSON.stringify(data.qty))
                    // window.localStorage.setItem('prodcode', JSON.stringify(data.prodCode))
                    return { ...data, qty: !data.qty ? 0 : data.qty }
                });
        }

        // window.localStorage.setItem('added-items', JSON.stringify(sendData));
        // window.localStorage.setItem("selcted-Products", JSON.stringify(sendData));
        // setvaluesqtys(localStorage.getItem("qty"))
        return () => {
            isComponentMounted = false
        }
    }, [searchProduct])







    function addValuehandler(elm, item) {
        //setProdName(productData)
        setComCode(elm.compCode);
        setShowList(true);

        console.log(elm.compCode);

        console.log(productData)

    }
    console.log(OrderbyCompRate);

    useEffect(() => {

        let sum = 0;
        searchProduct.forEach(item => {
            const amount = (item.qty ? parseFloat(item.qty) : 0) * (item.rate ? parseFloat(item.rate) : 0);
            let gstAmt = (sum * item.gst / 100);
            console.log(sum)
            sum += amount + gstAmt;
        })
        setIncreasePrice(sum.toFixed(2));

        return () => {

        };
    }, [searchProduct])


    // useEffect(() => {
    //     localStorage.setItem("ORDERBYCOMP_TOTALprice", increasePrice)
    //     return () => { }
    // }, [increasePrice])







    return (
        <>
            <HeaderCustomer />
            <div className="POD">
                <h1 style={{
                    textAlign: "center",
                    paddingBottom: "20px",
                    fontFamily: "Courier",
                    fontWeight: 900,
                    fontSize: "28px"
                }}
                >Place order Company wise</h1>

            </div>




            <div className='compamy_list row m-0'>
                <div className='col-lg-8 offset-lg-2'>

                    <h6 >Company</h6>

                    <Form.Select

                        value={comCode}
                        aria-label="Default select example"
                        className='h-sm'
                        onChange={(e) => {
                            console.log("SELECT", e);
                            addValuehandler({ compCode: e.target.value })
                            setComCode(e.target.value)
                        }}
                    >
                        {data.map((elm, i) => {
                            return <option
                                key={elm.compCode}
                                value={elm.compCode}

                            >
                                {elm.name}
                            </option>

                        })}


                    </Form.Select>





                    <div className="ProductSearch">
                        <h6>Filter</h6>
                        <Form style={{ border: " 2px solid #7bddc7", borderRadius: '10px' }}>
                            <InputGroup size="lg" >
                                <Form.Control className='search_products' placeholder='Enter Product Name...' onChange={
                                    filterBySearch
                                } />
                            </InputGroup>
                        </Form>
                    </div>


                    {/* <form onSubmit={handleSubmit}> */}
                    <div className="grids"
                        style={{
                            width: "100%",
                            marginTop: "30px",
                            overflowX: "scroll"
                        }}>
                        <Table striped bordered variant=" "   >
                            <thead>
                                <tr style={{ backgroundColor: "#00aff0", color: "white", border: "1px solid black" }}>
                                    <th>#</th>
                                    {
                                        hideDateTime ?
                                            <th>Time</th>
                                            : null
                                    }
                                    <th>{TransferData.product_Name}


                                    </th>
                                    <th style={{ marginRight: "-50px" }}>{TransferData.pack}</th>
                                    <th style={{ marginRight: "-50px" }}>{TransferData.qty}</th>
                                </tr>
                            </thead>

                            {

                                showList ?



                                    <tbody >

                                        {searchProduct.map((item, i) => {

                                            return <tr key={i}
                                                style={{ border: "1px solid black" }}
                                            >
                                                <td >{i + 1}</td>
                                                {/* {
                                                hideDateTime ?
                                                    <td>{item.showTime}</td>
                                                    : null
                                            } */}
                                                <td >
                                                    <span className="PrdtName">{item.prodName}</span>
                                                    <br />
                                                    <p >
                                                        <span style={{
                                                            fontSize: "14px",
                                                            fontWeight: "600",
                                                            color: "rgb(106, 106, 106)"
                                                        }}
                                                        > Stock:</span>
                                                        {item.stock}
                                                    </p>
                                                    <p style={{
                                                        marginLeft: "80px",
                                                        marginTop: "-40px"
                                                    }}
                                                    >
                                                        <span style={{
                                                            fontSize: "14px",
                                                            fontWeight: "600",
                                                            color: "rgb(106, 106, 106)"
                                                        }}
                                                        > MRP: </span>
                                                        {item.mrp}</p>
                                                    <p style={{
                                                        marginBottom: "-5px",
                                                        marginTop: "-10px"
                                                    }}>
                                                        <span style={{
                                                            fontSize: "14px",
                                                            fontWeight: "600",
                                                            color: "rgb(106, 106, 106)"
                                                        }}
                                                        >   Scheme: </span>
                                                        {item.scheme}</p>
                                                </td>
                                                <td style={{ marginRight: "-50px" }}> {item.case1}</td>

                                                <td style={{ marginRight: "-50px" }}>



                                                    <input
                                                        type='number'
                                                        value={item.qty ? item.qty : ''}
                                                        className="InputNumber"
                                                        min={0}
                                                        onChange={(e) => {
                                                            setOrderbyCompRate(item.rate)
                                                            SetsearchProduct(prev => {

                                                                console.log({ prev });
                                                                const res = prev.map((data, indexChange) => {
                                                                    if (indexChange === i) {
                                                                        return {
                                                                            ...data, qty: e.target.value,
                                                                        }
                                                                    }
                                                                    return data
                                                                })
                                                                return [...res]
                                                            })

                                                            setProductData(prev => {

                                                                console.log({ prev });
                                                                const res = prev.map((data, indexChange) => {
                                                                    if (indexChange === i) {
                                                                        return {
                                                                            ...data, qty: e.target.value,
                                                                        }
                                                                    }
                                                                    return data
                                                                })
                                                                return [...res]
                                                            })


                                                        }}

                                                    />



                                                </td>
                                            </tr>

                                        })}

                                    </tbody>




                                    : null
                            }

                        </Table>

                    </div>




                    <div className='row m-0'>


                        <div className='col-lg-6'>
                            {/* <div className="DropC  " >
                                <h5
                                    style={{ fontFamily: "Futara", color: "rgb(89, 91, 91)" }}>Priority</h5>
                                <select className='form-control'>
                                    <option>Normal</option>
                                    <option>Urgent</option>
                                </select>
                            </div>

                            <div className="remarks">
                                <h5 style={{ fontFamily: "Futara", color: "rgb(89, 91, 91)" }}>Remarks</h5>

                                <textarea
                                    className='form-control'
                                    name="Text1" cols="68"
                                    rows="2"
                                    onChange={(e) => setRemarksMessageBYcomp(e.target.value)}
                                ></textarea>

                                
                            </div> */}

                            <h5 style={{
                                marginTop: "50px",
                                marginBottom: "50px",
                                fontFamily: "Courier",
                                color: "rgb(89, 91, 91)",
                                fontSize: "18px",
                                fontWeight: "bold"
                            }}
                            >Approx Order Value : Rs.
                                {increasePrice}
                            </h5>


                            <button
                                type="button"
                                className="SubmitButton"
                                onClick={handleSubmit}

                            >Send to Order</button>


                        </div>
                    </div>

                </div>
            </div >


        </>
    )
}