import { configureStore } from "@reduxjs/toolkit";

import UserReducer from "./UserOrders";

const UserProductstore = configureStore({
    reducer: {
        userOrders: UserReducer,

    },
});

export default UserProductstore;