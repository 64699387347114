import React from 'react';
import './App.css';


function Footer() {
    return (
        <>
            <footer className='footer' style={
                {
                    bottom: 0,
                    // position: "fixed",
                    width: "100%"
                }}>
                <div className='row m-0' style={{
                    fontSize: 18,
                    fontWeight: 700,
                }}>
                    <div className='col-md-5 pl-5 offset-md-1'>
                        <p>C.S.NO 11142, W.NO 10, H.NO. 144, Ground Floor,<br /> Radhakrishna theater, M.NO 102000592,Ichalkaranji<br /> 416115, Maharashtra<br />
                            GSTIN:27ADRFS2459P1Z1</p>

                    </div>
                    <div className='col-md-6'>
                        <div className='row'>
                            <div className=' col-md-4  '>
                                {/* <ul style={{ listStyle: "none" }}>
                                    <li>bank details</li>
                                    <li>Current A/C. No.</li>
                                    <li>IFSC Code</li>
                                </ul> */}
                            </div>
                            <div className=' col-md-8'>
                                <div className='row'>
                                    <div className=' col-md-6  '>
                                        <ul style={{ listStyle: "none" }}>
                                            <li>bank details</li>
                                            <li>Current A/C. No.</li>
                                            <li>IFSC Code</li>
                                        </ul>
                                    </div>
                                    <div className=' col-md-6  '>
                                        <ul style={{ listStyle: "none" }}>

                                            <li>Cosmos Bank, Ichalkaranji</li>
                                            <li>114100103162</li>
                                            <li>COSB0000114</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {/* <div className='inside_f p-1 pt-2'>
                    <p>C.S.NO 11142, W.NO 10, H.NO. 144, Ground Floor,<br /> Radhakrishna theater, M.NO 102000592,Ichalkaranji<br /> 416115, Maharashtra<br />
                        GSTIN:27ADRFS2459P1Z1</p>
                    <div className='inside_ff'>
                        <ul>
                            <li>bank details</li>
                            <li>Current A/C. No.</li>
                            <li>IFSC Code</li>
                        </ul>


                    </div>
                    <div className='inside_fff'>
                        <ul>
                            <li>Cosmos Bank, Ichalkaranji</li>
                            <li>114100103162</li>
                            <li>COSB0000114</li>
                        </ul>


                    </div>
                </div> */}
            </footer>
        </>
    )
}
export default Footer;